import { useState, useEffect, useContext } from "react";
import { fetchData } from '../components/Helper'
import GlobalContext from '../components/GlobalContext';
import { ButtomContent, CheckboxFilter, CityFilter, FilteredData, FilterHeader, OdoMeter, PriceFilter, RadioFilter, SearchVehicle, SelectVehicleType, TokenPaidTill, TopContent, YearFilter } from "./AuctionDetailsComponents";
import { useHistory } from "react-router-dom";

function Auction(){
    const [context] = useContext(GlobalContext);
    const [data, setData] = useState()
    const [vehicleSetting, setVehicleSetting] = useState('')
    const [category, setCategory] = useState('')
    const [filter, setFilter] = useState({})
    const history = useHistory()

    const filterAgain = (data = false, vehicle_category = false) => {
        let formdata = '';
        if(data){
            if(!(document.getElementById("filterForm"))) return
            
            formdata = new FormData(document.getElementById("filterForm"))
            formdata.append('user_id', context ? context.id : null)
            formdata.append('category', category)
        }else{
            if(localStorage.getItem('formdata')){
                formdata = JSON.parse(localStorage.getItem('formdata'))
                formdata.category = vehicle_category ?? 'road_worthy_vehicles'
                setFilter(JSON.parse(localStorage.getItem('formdata')))
            }else{
                formdata = new FormData()
                formdata.category = vehicle_category ?? 'road_worthy_vehicles'
            }
        }

        fetchData(`auction-list`, 'POST', formdata, false, data, (res) => {
            if(res.data){
                if(res.data.filter) localStorage.setItem('formdata', res.data.filter);
                if(res.data.data) {
                    setData(res.data.data)
                    let p_id = document.getElementById('pagination');
                    p_id.innerHTML = ''
                    for (let i = 1; i <= res.data.data.last_page; i++) p_id.innerHTML += `<option ${res.data.data.current_page === i && 'selected'} >${i}</option>`
                }else{
                    setData(false)
                }
            }
        })
    }

    const setCategoryData = (value)=>{
        document.getElementsByClassName('cat-btn')[0].classList.add('d-none')
        setCategory(value)
        filterAgain(false, value)
    }

    useEffect(() => {
        fetchData(`vehicle-setting-type`, 'GET', '', false, false, (res) => {
            if(res.data) setVehicleSetting(res.data)
        })

        if(history && history.action === 'POP' && localStorage.getItem('backData')){
            let back_data = localStorage.getItem('backData')
            if(back_data.split(',')[0] === 'accidental_or_faulty_vehicles') document.getElementById('accidental_or_faulty_vehicles').click()
            else if(back_data.split(',')[0] === 'road_worthy_vehicles') document.getElementById('road_worthy_vehicles').click()
            localStorage.removeItem('backData')
        }else{
            localStorage.removeItem('backData')
            if(context && context.id){
                fetchData(`auction-filter-list/${context.id}`, 'GET', '', false, false, (res) => {
                    if(res && res.data){
                        setFilter(res.data)
                        filterAgain(true)
                    }
                })
            }else{
                filterAgain()
            }
        }
        
    }, [context, history])

    return (
    <>
        <TopContent />
        <section className="horizental-view py-5">
            <div className="container">
                <SelectVehicleType category={category} setCategoryData={setCategoryData} />
                { category &&
                    <div className="row">
                        <div className="col-sm-12 col-lg-3 col-xl-2">
                            <div className="refine-box">
                                <div className="card shadow-sm">
                                    <h6 className="card-header fw-medium bg-dark text-white p-3">My Searches</h6>
                                    <div className="card-body p-0 bg-light">
                                        <form id="filterForm">
                                            <SearchVehicle name="searching" placeholder="Search..." value={filter.searching ?? ''} filter={filterAgain} />
                                            <SearchVehicle name="location" placeholder="Search location..." value={filter.location ?? ''} filter={filterAgain} />
                                            {vehicleSetting && (vehicleSetting.vehicle_popular_city || vehicleSetting.vehicle_city) && <CityFilter name="city_of_registration" value={filter.city_of_registration ?? ''} popular_city={vehicleSetting.vehicle_popular_city} city={vehicleSetting.vehicle_city} filter={filterAgain} /> }
                                            <div className="accordion accordion-sm accordion-flush" id="flushFilter">
                                                {vehicleSetting && vehicleSetting.vehicle_make && <CheckboxFilter name="make" data={vehicleSetting.vehicle_make} value={filter.make ?? []} filter={filterAgain} /> }
                                                {vehicleSetting && vehicleSetting.vehicle_model && <CheckboxFilter name="model" data={vehicleSetting.vehicle_model} value={filter.model ?? []} filter={filterAgain} /> }
                                                <PriceFilter min_price_range={filter.min_price_range ?? ''} max_price_range={filter.max_price_range ?? ''} filter={filterAgain} />
                                                <YearFilter min_year={filter.min_year} max_year={filter.max_year} filter={filterAgain} />
                                                <OdoMeter odometer={filter.mileage ?? []} filter={filterAgain} />
                                                {vehicleSetting && vehicleSetting.vehicle_colour && <CheckboxFilter name="colour" data={vehicleSetting.vehicle_colour} value={filter.colour ?? []} filter={filterAgain} /> }
                                                {vehicleSetting && vehicleSetting.vehicle_typ && <CheckboxFilter name="type" data={vehicleSetting.vehicle_type} value={filter.type ?? []} filter={filterAgain} /> }
                                                {vehicleSetting && vehicleSetting.fuel && <CheckboxFilter name="fuel" data={vehicleSetting.fuel} value={filter.fuel ?? []} filter={filterAgain} /> }
                                                { category === 'accidental_or_faulty_vehicles' && <>
                                                <RadioFilter title="Starts" name="engin_start" data={[{label: "Doesn't Start", value: 'No'}, {label: "Starts", value: 'Yes'}]} value={filter.engin_start ?? ''} filter={filterAgain} />
                                                <RadioFilter title="Drives" name="drive" data={[{label: "Doesn't Drive", value: 'No'}, {label: "Drive", value: 'Yes'}]} value={filter.drive ?? ''} filter={filterAgain} />
                                                </> }
                                                {vehicleSetting && vehicleSetting.vehicle_transmission && <RadioFilter preDefinedData={true} title="Transmission" name="transmission" data={vehicleSetting.vehicle_transmission} value={filter.transmission ?? ''} filter={filterAgain} /> }
                                                <CheckboxFilter name="number_of_doors" data={['1','2','3','4','5','6','7','8','9','10']} value={filter.number_of_doors ?? []} filter={filterAgain} />
                                                <RadioFilter title="Ownership Document Present" name="ownership_document_present" data={[{label: "Present", value: '1'}, {label: "Not Present", value: '0'}]} value={filter.ownership_document_present ?? ''} filter={filterAgain} />
                                                <TokenPaidTill token_paid_till={filter.token_paid_till} filter={filterAgain} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-9 col-xl-10">
                            <div className="grid-list-view">
                                <FilterHeader filter={filterAgain} price={filter.sort_by_price} page={filter.page} />
                                { data && data.data ? <FilteredData data={data.data} /> :
                                <div className="row">
                                    <div className="btn-tiled mt-3 px-4" id="listgridView">
                                        <h4 className="text-center alert btn-primary w-10">There is no data available.</h4>
                                    </div>
                                </div> }
                                <div className="row row-cols-1">
                                    <div className="col">
                                        <p className="opacity-80 lh-base mt-3">
                                            Any vehicles that are listed as a private sale can be recognised with blue highlights and from the location which is not one of the Quality Car Auction sites, it means the vehicle is sold directly from a private seller's site. The buyer will deal directly with the seller regarding payment and collection etc. It is buyer's responsibility to confirm the accuracy of the information supplied and it is recommended that further validity checks are placed by the buyer before placing a bid on vehicles listed as private sales directly from the seller's site.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> }
                <ButtomContent />
            </div>
        </section>
    </>
    )
}

export default Auction
